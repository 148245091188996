import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup, Dropdown, DropdownButton, Form, ProgressBar, Spinner, Table } from 'react-bootstrap';

import axios from 'axios';
import moment from 'moment-timezone';

import './App.css';

class TableData {
  constructor(id, time, file, status) {
    this.id = id
    this.time = time
    this.file = file
    this.status = status
  }
}

function dateTimeFormat(time) {
  return moment(time).format('Y/M/D HH:mm:ss');
}

function App() {

  const [maxHistory, setMaxHistory] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [deletingTableData, setDeletingTableData] = useState(null);

  const [selectedFile, setSelectedFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL

  const handleRefresh = async () => {
    try {
      const response = await axios.get(`${API_URL}/status`);
      const data = response.data

      console.log(data);

      setMaxHistory(data.max_history);

      const newTableData = data.table.map((row, index) => (
        new TableData(
          row['id'],
          dateTimeFormat(row['upload_time']),
          row['filename'],
          row['status'] ? {
            status: row['status']['status'],
            message: row['status']['message'],
          } : null
        )
      ));

      console.log(newTableData)

      setTableData(newTableData);

    } catch (error) {
      console.error(error);
    }
  }

  const handleFileSelect = (e) => {
    setSelectedFile(e.target.files ? e.target.files[0] : null);
    setProgress(0);
    setUploading(false);
  }

  const handleFileUpload = async () => {
    setUploading(true);

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await axios.post(`${API_URL}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (e) => {
          const progress = (e.loaded / e.total) * 100;
          setProgress(progress);
        }
      });

      console.log(response.data);
    } catch (error) {
      console.error(error);
    }

    setUploading(false);

    handleRefresh();
  }

  const handleCalculate = async (id) => {
    try {
      const response = await axios.put(`${API_URL}/calculate/${id}`);

      console.log(response.data);
    } catch (error) {
      console.error(error);
    }

    handleRefresh();
  }

  const handleDelete = async (id) => {
    setDeletingTableData(id);

    try {
      const response = await axios.delete(`${API_URL}/delete/${id}`);

      console.log(response.data);
    } catch (error) {
      console.error(error);
    }

    setDeletingTableData(null);
    handleRefresh();
  }

  useEffect(() => {
    handleRefresh();
  }, []);

  useEffect(() => {
    const refreshInterval = 900;
    const shouldRefresh = tableData && tableData.some(row => row.status.status === 'pending' || row.status.status === 'calculating');

    if (shouldRefresh) {
      const refreshTimeout = setTimeout(() => {
        handleRefresh();
      }, refreshInterval);

      return () => {
        clearTimeout(refreshTimeout);
      }
    }

  }, [tableData]);

  return (
    <div className="App d-grid gap-3">
      <header>
      </header>
      <p>
          최대 {maxHistory}개까지 기록되며, 추가로 업로드 시 오래된 순으로 삭제됩니다.
      </p>
      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>배차 엑셀 파일</Form.Label>
        <Form.Control type="file" size="lg" onChange={ handleFileSelect } />
      </Form.Group>
      <Button size="lg" onClick={ handleFileUpload }
        disabled={ selectedFile == null || uploading }>
        { uploading ? (<Spinner animation="border" size="sm" />) : "업로드" }
      </Button>
      { uploading ? (<ProgressBar now={progress} />) : null }
      <Table bordered>
        <thead>
          <tr>
            <th className='col1'>ID</th>
            <th className='col2'>업로드 시간</th>
            <th className='col3'>업로드 파일</th>
            <th className='col4'>상태</th>
            <th className='col5'>삭제</th>
          </tr>
        </thead>
        <tbody>
          { tableData && tableData.map((row, index) => (
            <tr key={row.id}>
              <td>{row.id.slice(-10)}</td>
              <td>{row.time}</td>
              <td>
                <Button variant="light" size="sm"
                  href={`${API_URL}/download/input/${row.id}`}
                  download={row.file}
                  target="_blank" rel="noopener noreferrer">
                  {row.file}
                </Button>
              </td>
              <td>{ row.status.status === 'validation_error' ? (
                <DropdownButton
                  id="dropdown-variants-danger"
                  variant="danger"
                  title="유효성 검사 실패">
                  {row.status.message && row.status.message.split('\n').map((mrow, _) => (
                    <Dropdown.Item>{mrow}</Dropdown.Item>
                  ))}
                </DropdownButton>
              ) : row.status.status === 'waiting' ? (
                <Button variant="warning"
                  onClick={ () => handleCalculate(row.id) }>
                  계산하기
                </Button>
              ) : row.status.status === 'pending' ? (
                "대기 중"
              ) : row.status.status === 'calculating' ? (
                "계산 중"
              ) : row.status.status === 'done' ? (
                <Button variant="success"
                  href={`${API_URL}/download/output/${row.id}`}
                  download={`배차결과-${row.id}.xlsx`}
                  target="_blank" rel="noopener noreferrer">
                  결과 다운로드
                </Button>
              ) : row.status.status === 'fail' ? (
                <>
                  <DropdownButton
                    as={ButtonGroup}
                    id="dropdown-variants-danger"
                    variant="danger"
                    title="계산 실패">
                    {row.status.message && row.status.message.split('\n').map((mrow, _) => (
                      <Dropdown.Item>{mrow}</Dropdown.Item>
                    ))}
                  </DropdownButton>
                  {' '}
                  <Button variant="warning" as={ButtonGroup}
                    onClick={ () => handleCalculate(row.id) }>
                    재계산
                  </Button>
                </>
              ) : (row.status.status) }</td>
              <td>
                <Button variant="danger"
                  onClick={ () => handleDelete(row.id) }
                  disabled={ deletingTableData != null }>
                  { deletingTableData === row.id ? (
                    <Spinner animation="border" size="sm" />
                  ) : "삭제" }
                </Button>
              </td>
            </tr>
          )) }
        </tbody>
      </Table>
      <div>
        <Button variant="secondary" onClick={ handleRefresh }>
          새로고침
        </Button>
      </div>
    </div>
  );
}

export default App;
